import { Injectable } from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor() { }
  get apiUrl(): string {
    return environment.production ? 'https://api.ecademictube.com' : 'https://api.ecademictube.com';
    // return environment.production ? 'https://api.ecademictube.com' : 'http://localhost:5000';
  }
  get reviewBaseUrl(): string {
    return environment.production ? 'https://webportal.ecademictube.com' : 'https://webportal.ecademictube.com';
  }

  get mediaBaseUrl(): string {
    return environment.production ? 'https://bt-et-question-answers.s3.ap-south-1.amazonaws.com/' : 'https://bt-et-question-answers.s3.ap-south-1.amazonaws.com/';
  }
}
