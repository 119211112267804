<!-- <div class="outerContainer"> -->
<!-- <nz-card style="background-color: aliceblue;"> -->
<!-- <nz-result [nzIcon]="'smile-twotone'" [nzTitle]="'Thank You, For Your Valuable Rating.'">
      <div nz-result-extra>
        <button nz-button nzType="primary">Next</button>
      </div>
    </nz-result> -->
<!-- </nz-card> -->

<!-- </div> -->
<!-- <nz-card> -->
  <div class="contentareareview">
    <ng-container *ngFor="let review of allReviewQuestions; index as i">
      <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="name"
            >{{ i + 1 }}. {{ review.question }}
          </nz-form-label>
          <nz-form-control
            [nzSpan]="24"
            nzErrorTip="Should not be blank"
            *ngIf="review.questionType === 'radio'"
          >
            <nz-radio-group [(ngModel)]="review.answer">
              <ng-container *ngFor="let option of review.options">

                <label
                  nz-radio
                  nzValue="{{ option }}"
                  >{{ option }}</label
                >
                wwww
                <br>
              </ng-container>
            </nz-radio-group>
          </nz-form-control>
  
          <nz-form-control
            [nzSpan]="24"
            nzErrorTip="Should not be blank"
            *ngIf="review.questionType === 'checkbox'"
          >
            <nz-checkbox-group [(ngModel)]="review.options"></nz-checkbox-group>
          </nz-form-control>
  
          <nz-form-control
            [nzSpan]="24"
            nzErrorTip="Should not be blank"
            *ngIf="review.questionType === 'text'"
          >
            <textarea nz-input [(ngModel)]="review.answer" type="text"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </ng-container>
    <button nz-button (click)="submitReview()">Submit</button>
  </div>
 
<!-- </nz-card> -->


