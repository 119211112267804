<div class="logo">
  <img
    src="assets/images/et-new-logo.png"
    alt="logo"
    class="sidebarLogo"
    *ngIf="!isCollapsed; else collapselsepart"
  />
  <ng-template #collapselsepart
    ><img src="assets/images/et-new-logo.png" alt="logo" class="miniSidebarLogo" />
  </ng-template>
</div>

<ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed" nzTheme="dark">
  <!-- <li nz-menu-item nzMatchRouter>
    <a [routerLink]="['/', 'main', 'isp-main']">Isp Main</a>
  </li>
  <li nz-menu-item nzMatchRouter>
    <a [routerLink]="['/', 'main']">Isp Sub</a>
  </li> -->
  <ng-container *ngFor="let item of menuData">
    <li
      nz-menu-item
      nzMatchRouter
      [nzSelected]="item.selected"
      *ngIf="!item.children || item.children.length === 0"
      routerLinkActive="router-link-active"
      [routerLink]="[item.route]"
      (click)="sendOnMenu()"
    >
      <i nz-icon [nzType]="item.icon"></i>
      <span>{{ item.title }}</span>
    </li>
    <li
      nz-submenu
      *ngIf="item.children && item.children.length > 0"
      [nzTitle]="item.title"
      [nzIcon]="item.icon"
      routerLinkActive="router-link-active"
      [nzOpen]="false"
    >
      <ul>
        <li
          nz-menu-item
          nzMatchRouter
          [routerLink]="[data.route]"
          *ngFor="let data of item.children"
          (click)="sendOnMenu()"
        >
          {{ data.title }}
        </li>
      </ul>
    </li>
  </ng-container>
</ul>
