import { ReadVarExpr } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpRequestService } from 'src/app/core/services';

@Component({
  selector: 'app-student-review',
  templateUrl: './student-review.component.html',
  styleUrls: ['./student-review.component.scss'],
})
export class StudentReviewComponent implements OnInit {
  allReviewQuestions:any[] = [];
  constructor(
    private route: ActivatedRoute,
    private httpRequestService: HttpRequestService
  ) {

  }

  ngOnInit(): void {
    this.getAllReviews();
  }

  getAllReviews(): void {
    this.httpRequestService
        .request('get', 'reviews')
        .subscribe((success) => {
          success.data.map((x: any)=> {
            if(x.questionType === 'checkbox') {
              x.answer = [];
              x.options = x.options.map((option:any)=> ({
                label: option,
                value: option,
                checked: false
              }))
            }else {
              x.answer = '';
            }
          } );
          this.allReviewQuestions = success.data;
        });
  }

  submitRating(): void {
    this.route.queryParams.subscribe((params) => {
      this.httpRequestService
        .request('post', 'student-ratings', params)
        .subscribe((success) => {
        });
    });
  }


  submitReview() {
  }
}
