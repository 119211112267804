import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  authLogout: Subject<any> = new Subject<any>();

  // for daily work
  studentFormSubmitForDailyWork: Subject<any> = new Subject<any>();
  branchFormSubmitForDailyWork: Subject<any> = new Subject<any>();
  subjectFormSubmitForDailyWork: Subject<any> = new Subject<any>();
  reasonFormSubmitForDailyWork: Subject<any> = new Subject<any>();
  deviceFormSubmitForDailyWork: Subject<any> = new Subject<any>();
  tutorFormSubmitForDailyWork: Subject<any> = new Subject<any>();

  //for course work
  studentFormSubmitForCourseWork: Subject<any> = new Subject<any>();
  branchFormSubmitForCourseWork: Subject<any> = new Subject<any>();
  subjectFormSubmitForCourseWork: Subject<any> = new Subject<any>();
  reasonFormSubmitForCourseWork: Subject<any> = new Subject<any>();
  deviceFormSubmitForCourseWork: Subject<any> = new Subject<any>();
  tutorFormSubmitForCourseWork: Subject<any> = new Subject<any>();

  // for student form
  universityFormSubmit: Subject<any> = new Subject<any>();
  constructor() {}
}
