export const teamMemberMenu = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/main/dashboard',
    icon: 'home',
    children: [],
  },
  {
    key: 'manageFields',
    title: 'Manage Fields',
    route: '',
    icon: 'robot',
    children: [
      {
        key: 'branchManagement',
        title: 'Branch Management',
        route: '/main/branches',
        icon: 'branches',
        children: [],
      },
      {
        key: 'subjectManagement',
        title: 'Subject Management',
        route: '/main/subjects',
        icon: 'read',
        children: [],
      },
      {
        key: 'semesterManagement',
        title: 'Semester Management',
        route: '/main/semesters',
        icon: 'appstore-add',
        children: [],
      },
      {
        key: 'workTypes',
        title: 'Work Types',
        route: '/main/work-types',
        icon: 'database',
        children: [],
      },
      {
        key: 'courseTypes',
        title: 'Course Types',
        route: '/main/course-types',
        icon: 'snippets',
        children: [],
      },
      {
        key: 'specialisations',
        title: 'Specialisations',
        route: '/main/specialisations',
        icon: 'audit',
        children: [],
      },
      {
        key: 'reasons',
        title: 'Reasons',
        route: '/main/reasons',
        icon: 'ant-design',
        children: [],
      },
      {
        key: 'devices',
        title: 'Devices',
        route: '/main/devices',
        icon: 'ant-design',
        children: [],
      },
      {
        key: 'payment-method',
        title: 'Payment Methods',
        route: '/main/payment-methods',
        icon: 'money-collect',
        children: [],
      },
      // {
      //   key: 'missingWork',
      //   title: 'Missing Work',
      //   route: '/main/missing-work',
      //   icon: 'info-circle',
      //   children: [],
      // }
    ],
  },

  {
    key: 'userTypeManagement',
    title: 'User Type Management',
    route: '',
    icon: 'robot',
    children: [
      {
        key: 'teamMembers',
        title: 'Team Members',
        route: '/main/teammembers',
        icon: 'robot',
        children: [],
      },
      {
        key: 'UserManagement',
        title: 'User Management',
        route: '/main/users',
        icon: 'usergroup-add',
        children: [],
      },

      {
        key: 'tutorManagement',
        title: 'Tutor Management',
        route: '/main/tutors',
        icon: 'plus-square',
        children: [],
      },
      {
        key: 'studentManagement',
        title: 'Student Management',
        route: '/main/students',
        icon: 'user',
        children: [],
      },
      {
        key: 'university',
        title: 'University',
        route: '/main/university',
        icon: 'user',
        children: [],
      }
    ],
  },
  {
    key: 'workSpaceManagement',
    title: 'Work Space Management',
    route: '/main/work-space-management',
    icon: 'fund',
    children: [],
  },

  {
    key: 'dataManagement',
    title: 'Data management',
    route: '',
    icon: 'book',
    children: [
      {
        key: 'question',
        title: 'Question',
        route: '/main/questions',
        icon: 'book',
        children: [],
      },
      {
        key: 'videoSolution',
        title: 'Video Solution',
        route: '/main/video-solution',
        icon: 'book',
        children: [],
      },
      {
        key: 'studyMaterial',
        title: 'Study Material',
        route: '/main/study-material',
        icon: 'book',
        children: [],
      },
      {
        key: 'bookUpload',
        title: 'Book Upload',
        route: '/main/book-upload',
        icon: 'book',
        children: [],
      },
    ],
  },

  {
    key: 'report',
    title: 'Reports',
    route: '',
    icon: 'group',
    children: [
      {
        key: 'studentReportByFee',
        title: 'Student Report by Fee',
        route: '/main/student-report-by-fee',
        icon: 'info-circle',
        children: [],
      },
      {
        key: 'dailyWorkBranchReport',
        title: 'Daily Work Branch Report',
        route: '/main/report/daily-work-branch-roport',
        icon: 'info-circle',
        children: [],
      },
      {
        key: 'dailyWorkSubjectReport',
        title: 'Daily Work Subject Report',
        route: '/main/report/daily-work-subject-roport',
        icon: 'info-circle',
        children: [],
      },
      {
        key: 'courseWorkBranchReport',
        title: 'Course Work Branch Report',
        route: '/main/report/course-work-branch-roport',
        icon: 'info-circle',
        children: [],
      },
      {
        key: 'courseWorkSubjectReport',
        title: 'Course Work Subject Report',
        route: '/main/report/course-work-subject-roport',
        icon: 'info-circle',
        children: [],
      },
      {
        key: 'tutorReports',
        title: 'Tutor Reports',
        route: '/main/tutor-reports/daily-work',
        icon: 'fund',
        children: [],
      },
    ],
  },

  {
    key: 'others ',
    title: 'Others',
    route: '',
    icon: 'group',
    children: [
      {
        key: 'review',
        title: 'Reviews',
        route: '/main/review',
        icon: 'group',
        children: [],
      },
      {
        key: 'allReview',
        title: 'All Reviews',
        route: '/main/all-reviews',
        icon: 'group',
        children: [],
      },
      {
        key: 'emailTemplates',
        title: 'Email Templates',
        route: '/main/email-templates',
        icon: 'group',
        children: [],
      },
    ],
  },
];

export const contentWriterMenu = [
  {
    key: 'question',
    title: 'Questions',
    route: '/main/questions',
    icon: 'book',
    children: [],
  },
  {
    key: 'videoSolution',
    title: 'Video Solution',
    route: '/main/video-solution',
    icon: 'book',
    children: [],
  },
  {
    key: 'studyMaterial',
    title: 'Study Material',
    route: '/main/study-material',
    icon: 'book',
    children: [],
  },
  {
    key: 'bookUpload',
    title: 'Book Upload',
    route: '/main/book-upload',
    icon: 'book',
    children: [],
  },
];

export const contentCheckerMenu = [
  {
    key: 'videoSolution',
    title: 'Video Solution',
    route: '/main/video-solution',
    icon: 'book',
    children: [],
  },
];
