<nz-upload
    [nzAction]="mediaUploadUrl"
    [nzHeaders]="customRequestHeaders"
    [nzName]="'file'"
    (nzChange)="handleChangeForDocument($event)"
    [(nzFileList)]="fileList"
    [nzShowButton]="fileList.length < fileItemLength"
    nzListType="picture-card"
    [nzPreview]="handlePreview"
    [nzRemove]= "handleRemove"
>
    <div>
        <i nz-icon nzType="plus"></i>
        <div style="margin-top: 8px">Upload</div>
    </div>
</nz-upload>
<nz-modal
    [nzVisible]="previewVisible"
    [nzContent]="modalContent"
    [nzFooter]="null"
    (nzOnCancel)="previewVisible = false"
>
    <ng-template #modalContent>
        <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
    </ng-template>
</nz-modal>
