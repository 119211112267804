import { Component, Input, OnInit } from '@angular/core';
import { ConfigurationService } from 'src/app/core/services';

@Component({
  selector: 'app-document-view-modal',
  templateUrl: './document-view-modal.component.html',
  styleUrls: ['./document-view-modal.component.scss']
})
export class DocumentViewModalComponent implements OnInit {
  @Input() documents: any[] = [];
  mediaPreviewdUrl = '';

  constructor(
    private configurationService: ConfigurationService
  ) { }

  ngOnInit(): void {
    this.mediaPreviewdUrl = this.configurationService.apiUrl + '/uploads/';
  }


}
