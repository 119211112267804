import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { NzUploadFile } from "ng-zorro-antd/upload";
import {
    ConfigurationService,
    HttpRequestService,
} from "src/app/core/services";

const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

@Component({
    selector: "app-file-upload",
    templateUrl: "./file-upload.component.html",
    styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent implements OnInit, OnChanges {
    mediaUploadUrl = "";
    imageLoading = false;
    fileList: any[] = [];
    previewImage: string | undefined = "";
    previewVisible = false;
    @Input() fileItems: any;
    @Input() fileItemLength: any = 1;
    @Output() fileItemsChange: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private configurationService: ConfigurationService,
        private httpRequestService: HttpRequestService
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fileItems && this.fileItems.length) {
            this.fileList = this.fileItems.map((x: any) => ({
                uid: x._id,
                name: x.originalname,
                url: this.configurationService.mediaBaseUrl + x.path,
                response: { data: x },
            }));
        }
    }

    ngOnInit(): void {
        this.mediaUploadUrl = this.configurationService.apiUrl + "/api/media";
    }
    customRequestHeaders = () => {
        return { Authorization: `Bearer ${localStorage.getItem("token")}` };
    };
    handleChangeForDocument(info: { file: NzUploadFile }): void {
        switch (info.file.status) {
            case "uploading":
                this.imageLoading = true;
                break;
            case "done":
                this.imageLoading = false;
                this.fileItemsChange.emit(
                    this.fileList.map((x) => x.response.data)
                );

                break;
            case "error":
                // this.msg.error('Network error');
                this.imageLoading = false;
                break;
        }
    }

    handlePreview = async (file: NzUploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj!);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
    };

    handleRemove = (file: NzUploadFile): boolean => {
        const delteFileIndex = this.fileList.findIndex(
            (x) => x.uid === file.uid
        );
        this.fileList.splice(delteFileIndex, 1);
        this.fileItemsChange.emit(this.fileList.map((x) => x.response.data));
        this.httpRequestService
            .request("delete", `media/${file.uid}`)
            .subscribe((data) => {});
        return false;
    };
}
