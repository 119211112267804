<nz-header>
  <div nz-row>
    <div nz-col nzSpan="12">
      <i
        class="trigger"
        nz-icon
        [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
        (click)="onChangeCollapse()"
      ></i>
    </div>
    <div nz-col nzSpan="12" class="rightPart">
      <div>
        <nz-avatar
          nzIcon="user"
          class="avatar"
          nzSrc="assets/images/et-new-logo.png"
          nz-button
          nz-dropdown
          [nzDropdownMenu]="menu"
          [nzPlacement]="'bottomRight'"
        ></nz-avatar>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item>
              <a [routerLink]="['/main/profile']"
                ><i nz-icon nzType="user" nzTheme="outline"></i>My Profile</a
              >
            </li>
            <li nz-menu-item>
              <a [routerLink]="['/main/settings/']"
                ><i nz-icon nzType="setting" nzTheme="outline"></i>Settings</a
              >
            </li>
            <li nz-menu-item (click)="logout()">
              <a><i nz-icon nzType="logout" nzTheme="outline"></i>Logout</a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </div>
</nz-header>
