import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzUploadFile, UploadFilter } from "ng-zorro-antd/upload";
import { Observable, Observer } from "rxjs";
import {
    ConfigurationService,
    HttpRequestService,
} from "src/app/core/services";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-upload-box",
    templateUrl: "./upload-box.component.html",
    styleUrls: ["./upload-box.component.scss"],
})
export class UploadBoxComponent implements OnInit {
    @Input() attachments: any[] = [];
    @Output() attachmentsChange: EventEmitter<any[]> = new EventEmitter<
        any[]
    >();
    @Input() deletedAttachments: any[] = [];
    @Output() deletedAttachmentsChange: EventEmitter<any[]> = new EventEmitter<
        any[]
    >();
    @Input() deleteEnabled: boolean = false;
    mediaUploadPath = "";
    fileList: any[] = [];
    uploading: boolean = false;
    fileListForDisplay: any[] = [];
    attachmentsLoading = false;
    customHeaders: any = {
        Authorization: "Bearer " + localStorage.getItem("token"),
    };
    existingFiles: any[] = [];
    @Input() itemsPerLine: number = 3;
    constructor(
        private msg: NzMessageService,
        private configurationService: ConfigurationService,
        private httpRequestService: HttpRequestService
    ) {
        this.mediaUploadPath = configurationService.apiUrl + "/api/media";
    }

    filters: UploadFilter[] = [
        {
            name: "type",
            fn: (fileList: NzUploadFile[]) => {
                // tslint:disable-next-line:no-bitwise
                const filterFiles = fileList.filter(
                    (w: any) =>
                        ~[
                            "image/png",
                            "image/jpg",
                            "image/jpeg",
                            "image/tiff",
                            "application/pdf",
                            "application/pptx",
                            "application/xls",
                            "application/xlsx",
                        ].indexOf(w.type)
                );
                if (filterFiles.length !== fileList.length) {
                    this.msg.error(`Some Files are of Invalid type`);
                    return filterFiles;
                }
                return fileList;
            },
        },
        {
            name: "async",
            fn: (fileList: NzUploadFile[]) => {
                return new Observable((observer: Observer<NzUploadFile[]>) => {
                    // doing
                    observer.next(fileList);
                    observer.complete();
                });
            },
        },
    ];

    // tslint:disable-next-line:no-any
    handleChange(info: any): void {
        const fileList = info.fileList;
        this.fileList = [];
        // 2. read from response and show file link
        if (info.file.response) {
            info.file.url = info.file.response.url;
        }
        // 3. filter successfully uploaded files according to response from server
        // tslint:disable-next-line:no-any
        this.fileList = fileList.filter((item: any) => {
            if (item.response) {
                return item.response.success;
            }
            return true;
        });
        this.attachmentsLoading = fileList.some(
            (item: any) => !item.response && item.status !== "error"
        );
        if (!this.attachmentsLoading) {
            this.attachments = [
                ...this.attachments,
                ...this.fileList
                    .filter((x) => x.response)
                    .map((x) => x.response.data)
                    .filter(
                        (x) =>
                            !this.attachments.map((l) => l._id).includes(x._id)
                    ),
            ];
            this.attachmentsChange.emit(this.attachments);
        }
    }

    removeFile = (index: number): void => {
        const [deletedFile] = this.attachments.splice(index, 1);
        this.deletedAttachments = [...this.deletedAttachments, deletedFile];
        this.deletedAttachmentsChange.emit(this.deletedAttachments);
        this.attachmentsChange.emit(this.attachments);
    };

    ngOnInit() {}

    ngOnChanges() {
        this.existingFiles = [...this.attachments];
    }
}
