import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { contentCheckerMenu, contentWriterMenu, teamMemberMenu } from '../data/menu.data';
@Injectable({
  providedIn: 'root',
})
export class MenuService {
  menu: Subject<any[]> = new Subject<any[]>();

  constructor() {}
  public async generateMenu(user: any): Promise<any> {
    this.menu.next(teamMemberMenu);
    switch (user.role) {
      case 'team-member':
        this.menu.next(teamMemberMenu);
        break;
      case 'content-writer':
        this.menu.next(contentWriterMenu);
        break;
      case 'content-checker':
        this.menu.next(contentCheckerMenu);
        break;
        case 'default':
          break;
    }
  }
}
