<div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
    <!------First Name-->
    <div nz-col nzLg="24" nzMd="24" nzSm="24" nzXs="24">
        <div class="inner-box">
            <nz-form-item>
                <nz-form-label
                    [nzSpan]="24"
                    nzFor="rejectionReason"
                    nzRequired
                    class="formlabel"
                    >Reason
                </nz-form-label>
                <nz-form-control [nzSpan]="24" nzErrorTip="Should not be blank">
                    <textarea
                        nz-input
                        placeholder="reason"
                        [(ngModel)]="rejectionReason"
                        [nzAutosize]="{ minRows: 3, maxRows: 5 }"
                    ></textarea>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div style="text-align: center;">
        <button nz-button nzType="primary" (click)="submit()">Submit</button>
    </div>
</div>
