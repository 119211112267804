import { Component, OnInit } from "@angular/core";
import { NzModalRef } from "ng-zorro-antd/modal";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
    selector: "app-reject-content",
    templateUrl: "./reject-content.component.html",
    styleUrls: ["./reject-content.component.scss"],
})
export class RejectContentComponent implements OnInit {
    rejectionReason = "";
    constructor(
        private modalRef: NzModalRef,
        private notification: NzNotificationService
        ) {}

    ngOnInit(
    ): void {}

    submit() {
        if(!this.rejectionReason) {
            this.notification.error('','Reason can not be blank');
            return;
        }
        this.modalRef.close(this.rejectionReason)
    }
}
