
<div class="fileItem">
    <img nz-image *ngIf="type === 'image'" class="singe-image" [nzSrc]="brokenImage? 'assets/images/broken.png' : apiBaseUrl + src" [ngStyle]="dynamicStyle" (error)="brokenImage=true"/>
    <img *ngIf="type !== 'image'" class="singe-image" [src]="'assets/images/document.png'" [ngStyle]="dynamicStyle" style="width: 30px;" />
    <div class="overlay">
        <p *ngIf="fileName">{{fileName | slice: 0: 20}}</p>
        <div class="actions">

            <button *ngIf="!brokenImage && downloadEnabled" nz-button nzType="link" (click)="downloadFile()">
                <i nz-icon nzType="download"></i>
            </button>

            <button nz-button nzType="link" nz-popconfirm nzPopconfirmTitle="Are you sure?"  class="deleteItem" *ngIf="deleteEnabled" (nzOnConfirm)="fileDelete.emit(true)"><i nz-icon nzType="delete"></i></button>
        </div>

        <p *ngIf="brokenImage">Broken Image</p>
    </div>
</div>
